
  import { defineComponent, ref, watch, onMounted } from 'vue';
  import axios, { AxiosError } from 'axios';
  import useAlert from "@/composables/Alert"
  import router from '@/router';
  import {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
    CampTable,
    CampTableTh,
    CampTableTd,
    CampEmptyListFeedbackV2
  } from '@/components';
  import { campHandleEmptyFields } from '@/composables/DataValidation';
  import { useLoaderStore } from "@/store/LoaderStore";
  import ptBr from "element-plus/lib/locale/lang/pt-br";
  import moment from 'moment';
  import { TOptions, deleteMediaFromClient, uploadMediaFromClient } from '@/services/AzureBlobService';
  import { useRoute } from 'vue-router';
  import { getFileNameFromBlobUrl } from '../Social/ListPage.vue';
  import MediaSystemEngine from '@/views/AwardsCatalog/Components/MediaSystemEngine.vue';
  
  interface IAwardData {
      id?: number,
      title: string,
      description: string,
      media: string | null,
      active: 0 | 1,
      points: number,
      expiration_date: string,
  }

  interface IAwardResponse {
      data: IAwardData,
      error: boolean,
      message: string,
      errorCode: string
  }
  
  export interface IMediaFile {
    file: File | null
    URL: string | null
    youtubeURL: string | null
    contentType: string
    blobName: string
    URLFromParent: string | null
  }
  
  export const initMediaFile = {
    URL: null,
    youtubeURL: null,
    file: null,
    contentType: "",
    blobName: "",
    URLFromParent: ""
  }
  
  export default defineComponent({
    name: "AwardsCatalogPut",
    components: {
      CampHeader,
      CampFormHeader,
      CampFormRackSubmitBtn,
      CampTable,
      CampTableTh,
      CampTableTd,
      CampEmptyListFeedbackV2,
      MediaSystemEngine
    },
    setup() {
      const isLoading = ref(true)
      const route = useRoute()
      const idRoute = route.params.id
      const { showTimeAlert } = useAlert()
      const loaderStore = useLoaderStore()
      const isRequiredField = ref(false)
      const infoMaxCharacters = ref<string | null>(null)
      const infoMaxCharactersWarning = ref<string | null>(null)
      const activeStatus = ref(false)
      const currentDate = new Date()
      const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
      const mediaFile = ref<IMediaFile>({ ...initMediaFile })
      const INIT_DATA: IAwardData = {
        title: "",
        points: 10,
        description: "",
        media: "",
        expiration_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        active: activeStatus.value ? 1 : 0,
      }
      const formModel = ref<IAwardData>(INIT_DATA)
      const formModelAux = ref<IAwardData>({
          title: '',
          description: '',
          media: '',
          active: 0,
          points: 0,
          expiration_date: '',
      })
      const urlMediaBackup = ref<string | null>(null)
      const infoMaxTitle = ref<string | null>(null)
      const infoMaxCharactersTitle = ref<string | null>(null)

      // Functions
      async function getAwardView() {
      loaderStore.open();
          try {
              const response = await axios.get<IAwardResponse>(`/api/getAwardById?id=${idRoute}`);
              
              const { data } = response.data;

              formModel.value = {
                  id: data.id,
                  title: data.title,
                  description: data.description,
                  media: data.media,
                  active: data.active,
                  points: data.points,
                  expiration_date: data.expiration_date,
              };

              activeStatus.value = data.active === 1;
              mediaFile.value.URLFromParent = data.media   || "";
          } catch (error) {
              if (error instanceof AxiosError) {
                  if (error.response) {
                      showTimeAlert(error.response.data.message, "error");
                  } else {
                      showTimeAlert("Algo deu errado, tente novamente mais tarde", "error");
                  }
              } else {
                  showTimeAlert("Algo deu errado, tente novamente mais tarde", "error");
              }
          } finally {
              loaderStore.close();
          }
      }

      function handleSizeDesc(max = 255) {
        const length = formModel.value.description.length
        infoMaxCharacters.value = `${length}/${max}`
        if(length > max){
          infoMaxCharactersWarning.value = `Máximo de ${max} caracteres atingido!`
        }
        else{
          infoMaxCharactersWarning.value = null
        }
      }

      function handleSizeTitle(max = 50) {
        const title = formModel.value.title.length
        infoMaxTitle.value = `${title}/${max}`
        if(title > max){
          infoMaxCharactersTitle.value = `Máximo de ${max} caracteres atingido!`
        }
        else{
          infoMaxCharactersTitle.value = null
        }
      }

  
      const resetWarning = () => isRequiredField.value = false
  
      /** Date */
      const handleDisabledDateMin = (time: Date) => time.getTime() < maxDate.getTime()
  
      const helperDelFileClowd = async () => {
        if(typeof urlMediaBackup.value !== "string"){
          return
        }
        const url = getFileNameFromBlobUrl(urlMediaBackup.value)
        const lastIndex = url.lastIndexOf("/");
        const blobName = url.substring(lastIndex + 1);
  
        let folder = ""
        if(mediaFile.value.contentType.startsWith('image/')){
          folder = "image/"
        }
        if(mediaFile.value.contentType.startsWith('video/')){
          folder = "video/"
        }
        if(mediaFile.value.contentType.startsWith('application/pdf')){
          folder = "application/"
        }
  
        await deleteMediaFromClient(`training/${folder}${blobName}`)
      }
  
      async function onSubmitForm() {
        isRequiredField.value = false
  
        if(infoMaxCharactersWarning.value || infoMaxCharactersTitle.value){
          showTimeAlert('Verifique os campos obrigatórios', 'error')
          return true
        }
  
        formModel.value.expiration_date = moment(formModel.value.expiration_date).format('YYYY-MM-DD HH:mm:ss')
        formModel.value.active = activeStatus.value ? 1 : 0
        const { id, media, ...requiredFields } = formModel.value
  
        if(campHandleEmptyFields(requiredFields)) {
          showTimeAlert('Verifique os campos obrigatórios', 'error')
          isRequiredField.value = true
          return true
        }
  
        const bodyToUpdate = formModel.value
        
        if(mediaFile.value.file) {
          const options: TOptions = {
            blobHTTPHeaders: {
              blobContentType: mediaFile.value.contentType
            }
          }
          try {
            loaderStore.open()
            const { request } = (await uploadMediaFromClient(mediaFile.value.file, mediaFile.value.blobName, options))._response
            bodyToUpdate.media = request.url
            helperDelFileClowd()
          } catch (error) {
            loaderStore.close()
            return showTimeAlert('Erro ao salvar media, tente mais tarde!', 'error')
          }
        }
  
        if(!mediaFile.value.URL) {
          try {
            loaderStore.open()
            helperDelFileClowd()
          } catch (error) {
            loaderStore.close()
            return showTimeAlert('Erro ao excluir media, tente mais tarde!', 'error')
          }
        }
  
        /** Exclui URL no Banco do Blob removido na Nuvem */
        if(!mediaFile.value.URL){
          bodyToUpdate.media = null
        }
  
        /** Sobrepõe URL antiga para atualizar */
        if(mediaFile.value.youtubeURL){
          bodyToUpdate.media = mediaFile.value.youtubeURL
        }
  
        try {
          loaderStore.open()
          const response: IAwardResponse = await axios.put(`/api/updateReward`, { ...bodyToUpdate })
          showTimeAlert("Catálogo de prêmios atualizado com sucesso!")
          router.push("/catalogo-de-premios")
        } catch (error) {
          if(error instanceof AxiosError) {
            if(error.response) {
              showTimeAlert(error.response?.data.message, "error")
            }else {
              showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
            }
          } else {
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
          }
          loaderStore.close()
        }
      }
  
      const handleUpdatedMediaFile = (emittedObject: IMediaFile) => mediaFile.value = emittedObject

      watch(() => formModel.value.description, () => handleSizeDesc())
      watch(() => formModel.value.title, () => handleSizeTitle())
  
      // Life cycles
      onMounted(async() => {
        await getAwardView()
      })

      return {
        isRequiredField,
        isLoading,
        resetWarning,
        formModel,
        infoMaxCharacters,
        infoMaxCharactersWarning,
        activeStatus,
        ptBr,
        mediaFile,
        handleUpdatedMediaFile,
        handleDisabledDateMin,
        onSubmitForm,
        moment,
        infoMaxTitle,
        infoMaxCharactersTitle,
      }
    }
  })
  